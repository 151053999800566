// container
.skltbs-theme-light,
.skltbs-theme-dark {
    position: relative;

    .skltbs {
        // tab group
        &-tab-group {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            list-style: none;
            margin: 0;
            overflow-x: auto;
            padding: 0;
            scrollbar-width: none;

            // self clear floats
            &::after {
                content: '';
                clear: both;
                display: table;
            }
        }

        // tab items
        &-tab-item {
            //float: left;
            //margin: 0 $border-spacing $border-spacing 0;
        }

        // tabs
        &-tab {
            display: block;
            padding: $tab-padding;
            color: inherit;
            border: 0;
            font-family: inherit;
            font-size: 1rem;
            font-weight: $font-bold;
            appearance: none;
            user-select: none;
            touch-action: manipulation;

            white-space: nowrap;

            // disabled tabs
            &:disabled {
                background: transparent;
                cursor: not-allowed;
                touch-action: none;
            }
        }

        &-tab-prev, &-tab-next {
            align-content: center;
            background-color: $white;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: 1px solid $gray;
            color: $textcolor;
            display: none;
            height: 36px;
            opacity: 0.4;
            position: absolute;
            text-align: center;
            text-indent: -9999px;
            top: 10px;
            width: 36px;

            &:focus-visible {
                opacity: 1;
                outline: 3px solid $darkgray;
            }

            &.active {
                display: block;
                opacity: 0.7;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &-tab-prev {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15%2018L9%2012L15%206%22%20stroke%3D%22%2310251C%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
            left: 5px;
        }

        &-tab-next {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7.5%2015L12.5%2010L7.5%205%22%20stroke%3D%22%232E3F50%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
            right: 5px;
        }

        // contents to toggle
        &-panel {
            padding: $panel-padding;
            border: 2px solid;

            // accordion triggers
            &-heading {
                margin-top: $border-spacing;

                &:first-child {
                    margin-top: 0;
                }

                .skltbs-tab {
                    width: 100%;
                }
            }
        }
    }
}

$checkedIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>');
$checkedGreenIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="%236fbc85" /></svg>');
$minusOutlineIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>minus-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,13H17V11H7" fill="%232e405080" /></svg>');
$minusFilledIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>minus-circle</title><path d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill="%232e405080" /></svg>');
$closeIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" fill="%232e405080" /></svg>');
$partlyIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>checkbox-marked-circle-minus-outline</title><path d="M14.3 21.7C13.6 21.9 12.8 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C13.3 2 14.6 2.3 15.8 2.7L14.2 4.3C13.5 4.1 12.8 4 12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C12.4 20 12.9 20 13.3 19.9C13.5 20.6 13.9 21.2 14.3 21.7M7.9 10.1L6.5 11.5L11 16L21 6L19.6 4.6L11 13.2L7.9 10.1M15 17V19H23V17H15Z" fill="%232e405080" /></svg>');

$infoIcon: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!--%20License%3A%20MIT.%20Made%20by%20Microsoft%3A%20https%3A%2F%2Fgithub.com%2Fmicrosoft%2Fvscode-codicons%20--%3E%3Csvg%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23000000%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M8.568%201.031A6.8%206.8%200%200%201%2012.76%203.05a7.06%207.06%200%200%201%20.46%209.39%206.85%206.85%200%200%201-8.58%201.74%207%207%200%200%201-3.12-3.5%207.12%207.12%200%200%201-.23-4.71%207%207%200%200%201%202.77-3.79%206.8%206.8%200%200%201%204.508-1.149zM9.04%2013.88a5.89%205.89%200%200%200%203.41-2.07%206.07%206.07%200%200%200-.4-8.06%205.82%205.82%200%200%200-7.43-.74%206.06%206.06%200%200%200%20.5%2010.29%205.81%205.81%200%200%200%203.92.58zM7.375%206h1.25V5h-1.25v1zm1.25%201v4h-1.25V7h1.25z%22%2F%3E%3C%2Fsvg%3E');

.capability-matrix {
    margin: 0 0.25rem 1.5rem 0.25rem;

    @media only screen and (min-width: $screen-sm) {
        margin: 0 1rem 1.5rem 1rem;
    }
    .matrix-level {
        // TODO: uses checkbox color, add to const? or use $success: #6fbc85;
        background-color: #7DB985FF;
        font-size: $fs-s;
        margin-left: 0.5rem;
        padding: 3px 6px;
        text-decoration: none;

        &:focus-visible {
            outline: 3px solid $darkgray;
        }
    }

    .capability-legend {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 2rem 4rem;

        // keep the legend-label in own row
        .legend-label {
            margin-bottom: 0.5rem;
        }

        .capability-legend--value-label {
            min-width: 160px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media only screen and (min-width: $screen-sm) {
                min-width: unset;
                display: block;
            }

            i.capability-icon {
                height: 1.5rem;
                width: 1.5rem;
            }

            &:focus-visible {
                outline: 3px solid $darkgray;
            }
        }

        @media only screen and (min-width: $screen-sm) {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1.5rem;
            justify-content: center;

            .legend-label {
                margin-bottom: 0;
            }
        }
    }

    .capability-download-container {
        margin-bottom: 3rem;

        .button--download {
            margin-top: 0.75rem;
        }
    }

    .dt-container {
        > .dt-layout-row:first-child {
            margin-top: 0;
        }

        .dt-paging {
            .dt-paging-button {
                &.first, &.last {
                    display: none;
                }
            }
        }
        &.dt-empty-footer .dt-scroll-body {
            border-bottom: none;

            .matrix-datatable {
                border-top: 1px solid rgba(0, 0, 0, 0.3);
            }
        }
    }

    .datatable-row-group-title {
        left: 10px;
        position: sticky;
    }

    .matrix-level-modal {
        .modal-content {

            .modal-header {
                align-items: flex-start;

                .modal-header-content {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.5rem;

                    @media only screen and (min-width: $screen-sm) {
                        align-items: center;
                        column-gap: 2rem;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                }
            }

            .modal-body {
                max-height: 525px;
            }

            h3,h4 {
                font-weight: bold;
            }
        }

        .matrix-level-block {
            border-bottom: 1px solid $gray;
            margin: 2rem 0;
            padding-bottom: 1rem;

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    .asterisk-hint {
        margin-top: 2rem;
    }
}

i.capability-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 1.25rem;
    vertical-align: middle;
    width: 1.25rem;

    &.supported {
        background-image: $checkedGreenIcon;
    }
    &.limited {
        //background-image: $partlyIcon;
        background-image: $minusOutlineIcon;
    }
    &.not-supported {
        background-image: $closeIcon;
    }
    &.not-available {
        background-image: $minusFilledIcon;
    }
    &.info {
        background-image: $infoIcon;
    }
}

table.dataTable.matrix-datatable {
    font-size: $fs-xs;
    hyphens: auto;
    margin: 0;
    table-layout: fixed;
    width: auto !important; // override px width from datatable autoWidth

    thead {
        th {
            border-bottom: none !important;
            font-weight: 400;
            max-width: 250px;
            min-width: 120px;
            width: auto;
            padding: 5px;

            &:focus-visible {
                outline: 3px solid $darkgray;
                outline-offset: -3px;
            }

            .dt-column-order {
                display: none;
            }

            .dt-column-title {
                // disabled because kiwigrid prefers inline icons
                //display: flex;
                //align-items: center;
                //gap: 2px;
                //justify-content: space-between;
                //
                //.capability-icon.info {
                //    flex-shrink: 0;
                //}
            }

            @media only screen and (min-width: $screen-sm) {
                padding: 8px 10px;
                max-width: 250px;
                min-width: 150px;
            }
        }
    }
    tbody {
        td {
            // reduce spacing between multiline text
            line-height: 1.25rem;
            max-width: 250px;
            padding: 3px 6px;

            @media only screen and (max-width: $screen-sm) {
                padding: 5px;
            }

            &.matrix-columndata-td {
                &:has(.matrix-level) {
                    margin-left: 0.5rem;
                }
            }

            .icon-table-status {
                padding-left: 0.5rem;
            }
        }

        tr:hover td {
            background-color: $lightgray;
        }

        // grouped rows
        tr.dtrg-group {
            th {
                background-color: transparent;
                padding-top: 1.5rem;
            }

            &:first-child {
                th {
                    border-top: none;
                }
            }
        }
    }
    tbody tr td, thead tr th {

        &.counting-column {
            max-width: 40px;
            min-width: auto;
            text-align: center;
            width: 40px;

            // prevent :after for fixed .counting-column because another fixed column is coming after it
            &.dtfc-fixed-left::after {
                display: none;
            }
        }
    }
}

// adds box shadow to fixed header
html:has(.matrix-datatable) {
    .dtfh-floatingparent-head {
        box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
    }
}

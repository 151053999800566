@mixin dot($dynamic: true) {
  transform: translate(-50%,-50%);
  position: absolute;
  width: 31px;
  height: 31px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: background-color 0.2s ease;

  &:before {
    content: "";
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgba($white, 0.7);
    display: block;
    position: absolute;
    border-radius: 100%;
  }

  &:after {
    content: "";
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background-color: $textcolor;
    display: block;
    position: absolute;
    border-radius: 100%;
    transition: background-color 0.2s ease;
  }

  @if ($dynamic) {
    &.is-active {
      background-color: rgba($green, 0.5);
      animation-name: background-green-pulse;
      animation-duration: 3s;
      animation-iteration-count: infinite;

      &:after {
        background-color: $green;
      }
    }
  }
  @else {
    background-color: rgba($green, 0.5);
    animation-name: background-green-pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;

    &:after {
      background-color: $green;
    }
  }
}

@keyframes background-green-pulse {
  from { background-color: rgba($green, 0.8) }
  50% { background-color: rgba($green, 0.1) }
  to { background-color: rgba($green, 0.8) }
}


@mixin reset-text {
    font-family: $main-font;
    // We deliberately do NOT reset font-size or word-wrap.
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left; // Fallback for where `start` is not supported
    text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
}
